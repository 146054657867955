import Page from 'design-system/page/page';
import { AppDataContext } from 'design-system/shared/contexts/app-data/AppDataContext';
import {
  AppDataModel,
  GlobalNavContent,
  GlobalSettings,
  PageContent,
  PrimaryHeaderContent,
  GlobalAlertsContent,
  GlobalFooterContent,
  IconPack
} from 'design-system/shared/models/app-data-model';
import { graphql } from 'gatsby';
import React, { useContext, useEffect } from 'react';

export type ContentStackPageProps = {
  data: ContentStackPageQueryResponse;
};

export type ContentStackPageQueryResponse = {
  shPages: PageContent;
  shSpectrumhealthOrgPrimaryHeader: PrimaryHeaderContent;
  shSpectrumHealthOrgGlobalNav: GlobalNavContent;
  shSpectrumhealthOrgGlobalSettings: GlobalSettings;
  shGlobalAlertsSpectrumhealthOrg: GlobalAlertsContent;
  shSpectrumhealthOrgGlobalFooterNav: GlobalFooterContent;
};

const ContentStackPage: React.FC<ContentStackPageProps> = (props: ContentStackPageProps) => {
  const context = useContext(AppDataContext);

  useEffect(() => {
    if (context.setAppData) {
      const appData: AppDataModel = {
        ...context.appData
      };

      context.setAppData(appData);
    }
    // we can't depend on context or we endless loop!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page
      content={props.data.shPages}
      iconPack={props.data.shSpectrumhealthOrgGlobalSettings.site_theme.find((x) => x.icon_pack)?.icon_pack as IconPack[]}
      featureToggles={props.data.shSpectrumhealthOrgGlobalSettings.feature_toggles}
      globalSettings={props?.data?.shSpectrumhealthOrgGlobalSettings}
      globalFooterData={props?.data?.shSpectrumhealthOrgGlobalFooterNav}
      siteName={props.data.shSpectrumhealthOrgGlobalSettings.title}
      siteUrl={props.data.shSpectrumhealthOrgGlobalSettings.prod_url}
      globalNavData={props?.data?.shSpectrumHealthOrgGlobalNav}
      primaryHeader={props?.data?.shSpectrumhealthOrgPrimaryHeader}
    />
  );
};

export const ContentStackPageQuery = graphql`
  query ($id: String!) {
    shPages(id: { eq: $id }) {
      ...pageFragment
    }
    shSpectrumhealthOrgGlobalSettings {
      ...globalSettingsFragment
    }
    shSpectrumHealthOrgGlobalNav {
      ...globalNavFragment
    }
    shSpectrumhealthOrgPrimaryHeader {
      ...primaryHeaderFragment
    }
    shSpectrumhealthOrgGlobalFooterNav {
      ...globalFooterFragment
    }
  }
`;

export default ContentStackPage;
